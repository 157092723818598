<script>
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
// import { formatSlug } from '@/support/utils/stringHelper'
import { isValidURL } from '@/support/utils/stringHelper'

export default {
  name: 'EventsManage',
  components: {
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    AppMultiselect: () => import('@/components/general/AppMultiselect'),
    Upload: () => import('@/components/general/Upload')
  },

  data () {
    return {
      linkToEventTabActive: 0,
      linkToEventTab: [
        { text: 'event.manage:link.tab.1' },
        { text: 'event.manage:link.tab.2' }
      ],

      events: [],
      themeList: [],
      groupList: [],
      targetAudienceList: [],

      restrictedAccessOptions: [
        {
          label: this.$t('global:unrestricted'),
          value: false
        },
        {
          label: this.$t('global:restricted'),
          value: true
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:no'),
          value: false
        },
        {
          label: this.$t('global:yes'),
          value: true
        }
      ],

      eventPagination: {
        query: { name: '' },
        filter: {
          not_id: []
        },

        order: [],
        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      formData: {
        id: null,
        name: '',
        externalLink: null,
        callText: '',
        restrictedAccess: false,
        eventGroups: null,
        eventThemes: [],
        hasExternalLink: false,
        targetAudiences: [],
        cardImage: null,
        modality: 'Online'
      },

      allowSelectClassType: false,
      hasEvent: false,
      backUrl: { name: 'events.index' },
      modalConfirm: false,
      modalLeave: false,
      uploadStatus: null,
      validPodcastUrl: null,
      // isSlugDuplicate: false,
      isNameDuplicate: false,
      content: null,
      nameDebounceInterval: null,
      // slugDebounceInterval: null,
      pathsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {},

        lastPage: null,
        limit: 6,
        page: 1
      },

      isLoadingMorePaths: false,
      isLoadingMoreThematicPages: false,

      thematicPagesPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {},

        lastPage: null,
        limit: 6,
        page: 1
      },

      pathInfiniteScrollId: new Date()
        .getTime(),

      thematicPageInfiniteScrollId: new Date()
        .getTime(),

      showIntegrations: true,

      paymentTypeOptions: [
        {
          label: this.$t('global:free'),
          value: 'free'
        },
        {
          label: this.$t('global:paid'),
          value: 'paid'
        }
      ],

      minimumEventPrice: 0.0,
      minimumEventPriceErrorMessage: null,
      integrationAlias: null,
      isValidPortfolioId: true,
      portfolioId: null,

      agendaTypeOptions: [
        {
          label: this.$t('agendas.manage:agendaTypeOptions.online'),
          value: 'Online'
        },
        {
          label: this.$t('agendas.manage:agendaTypeOptions.presencial'),
          value: 'Presencial'
        },
        {
          label: this.$t('agendas.manage:agendaTypeOptions.hybrid'),
          value: 'Híbrido'
        }
      ]
    }
  },

  validations () {
    return {
      formData: {
        name: {
          required,
          nameDuplicate: () => {
            if (!this.formData.name) return true

            return this.isNameDuplicate
          }
        },

        // slug: {
        //   regexSlug: (event) => {
        //     if (!event) return true
        //     const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

        //     return regexSlug.test(event)
        //   },

        //   slugDuplicate: () => {
        //     if (!this.formData.slug) return true

        //     return this.isSlugDuplicate
        //   },

        //   required: requiredIf(function () {
        //     return this.formData.hasExternalLink === true
        //   })
        // },

        modality: { required },

        restrictedAccess: { required },

        eventGroups: {
          required: requiredIf(function () {
            return this.restrictedAccess
          }),

          minGroupsLength: (value, formData) => {
            return formData.restrictedAccess ? value ? value.length > 0 : true : true
          }
        },

        externalLink: {
          required: requiredIf(function () {
            return this.formData.hasExternalLink === true
          }),

          validURL: isValidURL
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    }
  },

  watch: {
    'formData.name' (event) {
      if (!event) return

      // if (!this.$v.formData.slug.$dirty && !this.isEditing) {
      //   this.formData.slug = formatSlug(this.formData.name)
      // }

      this.customDebounce('nameDebounceInterval', async () => {
        this.setFetching(true)
        const search = { exact_name: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const events = await this.attemptGetEventsList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isNameDuplicate = !(events.length > 0)

        if (!this.isNameDuplicate) {
          this.$v.formData.name.$touch()
        }

        this.setFetching(false)
      }, 1000)
    }

    // 'formData.slug' (event) {
    //   if (!event) return
    //   this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

    //   this.customDebounce('slugDebounceInterval', async () => {
    //     this.setFetching(true)
    //     const search = { slug: event }

    //     if (this.isEditing) {
    //       search.not_id = [ this.formData.id ]
    //     }

    //     const events = await this.attemptGetEventsList({ filter: search })
    //       .then(pagination => { return pagination.data })

    //     this.isSlugDuplicate = !(events.length > 0)

    //     if (!this.isSlugDuplicate) {
    //       this.$v.formData.slug.$touch()
    //     }

    //     this.setFetching(false)
    //   }, 1000)
    // }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getThemesList(),
      this.getGroupsList(),
      this.getTargetAudiencesList()
    ])
      .catch(() => {
        this.setFeedback({ message: this.$t('events.load:fetch.error') })
        this.$router.push({ name: 'events.index' })
      })
      .finally(() => {
        if (this.isEditing) {
          this.setFetching(true)

          this.attemptManageEvent(this.$route.params.id)
            .then((response) => {
              this.formData = response

              this.formData.eventGroups = response.GroupEvent.map((item) => {
                return {
                  text: item.Group.name,
                  value: item.Group.id
                }
              })

              this.formData.name = response.name
              this.formData.modality = response.modality
              this.formData.restrictedAccess = response.GroupEvent && response.GroupEvent.length > 0
              this.formData.eventThemes = response.EventTheme.map(item => item.Theme.alias)
              this.formData.targetAudiences = response.EventTargetAudience.map(item => item.TargetAudience.id)
              this.formData.externalLink = response.externalLink
              this.$set(this.formData, 'hasExternalLink', !!response.externalLink)
            })
            .catch((e) => {
              console.log(e)
              this.setFeedback({ message: this.$t('events.load:fetch.error') })
              // this.$router.push({ name: 'events.index' })
            })
            .finally(() => {
              this.setFetching(false)
            })
        } else {
          this.setFetching(false)
        }

        this.$v.$reset()
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateEvent',
      'attemptUpdateActiveEvent',
      'attemptSaveActiveEvent',
      'attemptGetThemesList',
      'attemptGetGroupsList',
      'attemptGetTargetAudiencesList',
      'attemptManageEvent',
      'attemptGetEventsList'
    ]),

    formatFormData () {
      const data = {}

      data.name = this.formData.name
      data.externalLink = this.formData.hasExternalLink ? this.formData.externalLink : null
      data.slug = this.formData.hasExternalLink ? this.formData.externalLink : null
      data.call_text = this.formData.callText
      data.card_image = this.formData.cardImage
      data.modality = this.formData.modality
      data.restricted_access = this.formData.restrictedAccess ? 1 : 0
      data.groups = this.formData.eventGroups ? this.formData.eventGroups.map(item => item.value) : []
      data.themes = this.formData.eventThemes ? this.formData.eventThemes : []
      data.targetAudience = this.formData.targetAudiences ? this.formData.targetAudiences : []
      // data.hasExternalLink = data.external_link ? 1 : 0
      // data.active = this.formData.active || false

      return data
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveActiveEvent({ data: this.formatFormData() })
        .then(({ data }) => {
          this.$router.push({
            name: 'events.manage',
            params: {
              id: data.id,
              isNew: 'edit'
            }
          })

          this.setFeedback({ message: this.$t('events:feedback.created.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateEvent({
        id: this.$route.params.id,
        data: formData
      })
        .then(() => {
          this.setFeedback({ message: this.$t('events:feedback.updated.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit (next) {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
          resolve(true)
        } else {
          this.setFeedback({ message: this.$t('events:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    getThemesList () {
      return this.attemptGetThemesList({})
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.themeList.push({
                text: element.name,
                value: element.alias
              })
            })

            return true
          }
        })
    },

    getTargetAudiencesList () {
      return this.attemptGetTargetAudiencesList({})
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.targetAudienceList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getGroupsList () {
      return this.attemptGetGroupsList({
        filter: {
          active: true
        },
        limit: 9999
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.groupList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getParsedErrorMessage (response) {
      if (response.data.error.message === 'upload_file_extension_not_allowed') {
        if (this.isEbook) {
          return this.$t('events:feedback.extension.ebook.error')
        }

        if (this.isPodcast) {
          if (!this.isEditing) {
            this.uploadStatus = null
          }

          return this.$t('events:feedback.extension.podcast.error')
        }

        return this.$t('events:feedback.extension.error')
      }

      if (response.data.error && response.data.error.validation) {
        if (response.data.error.validation.video === 'video_must_be_valid_vimeo_link') {
          return this.$t('events:feedback.url.vimeo.error')
        }

        if (response.data.error.validation.video === 'video_must_be_valid_youtube_or_vimeo_link') {
          return this.$t('events:feedback.url.youtube.vimeo.error')
        }

        if (response.data.error.validation.video === 'webinar_must_be_valid_youtube_or_vimeo_link') {
          return this.$t('events:feedback.url.webinar.youtube.vimeo.error')
        }

        // if (response.data.error.validation.external_link === 'external_link_must_be_valid_url') {
        //   return this.$t('events:feedback.url.externalActivity.error')
        // }
      }

      if (response.data.error.message === 'sas_unable_to_get_event') {
        return this.$t('events:feedback.sas.unable.get.event')
      }

      if (response.data.error.message === 'sas_get_event_error') {
        return this.$t('events:feedback.sas.get.event.error')
      }

      if (response.data.error.message === 'event__invalid_portfolio') {
        return this.$t('events:feedback.portfolio.invalid')
      }

      if (response.data.error.code === 10) {
        return response.data.error.message
      }

      return this.$t('events:feedback.created.error')
    },

    addEventGroup (item) {
      if (!this.formData.eventGroups) {
        this.formData.eventGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.formData.eventGroups = this.groupList
        } else {
          this.formData.eventGroups.push(item)
        }
      }
    },

    removeEventGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.formData.eventGroups = []
        } else {
          this.formData.eventGroups = this.formData.eventGroups.filter(eventGroup => eventGroup.value !== item.value)
        }
      }
    },

    customDebounce (intervalName, callback, time = 300) {
      clearTimeout(this[intervalName])

      this[intervalName] = setTimeout(() => {
        this[intervalName] = null
        callback(arguments)
      }, time)
    }
  }
}
</script>

<template>
  <div class="main-content events-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('events.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.events')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />

      <ActionBar slot="actionbar" />

      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="events-create-header">
          <h2 class="events-create-title">
            {{ $t('events.create:header.title') }}
          </h2>

          <p
            class="events-create-description"
            v-html="$t('events.create:header.description')"
          />

          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>

        <form
          class="form"
          @submit.prevent="submit"
        >
          <InputField
            v-model="formData.name"
            :label="$t('global:form.events.title')"
            :validation="$v.formData.name"
            :counter="100"
          />

          <InputField
            v-model="formData.callText"
            :under-description="$t('event.manage:field.description.calltext')"
            :label="$t('global:form.events.callText')"
            :counter="250"
          />

          <FormSection :title="$t('global:upload.add.image.card')">
            <Upload
              v-model="formData.cardImage"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.image.card.description')"
              cropper
              :width="385"
              :height="160"
              :preview="0.93"
            />
          </FormSection>

          <FormSection :title="$t('global:form.agendas.modality')">
            <Radio
              v-model="formData.modality"
              :under-description="$t('agendas.manage:field.description.presencial')"
              :items="agendaTypeOptions"
              horizontal
              :validation="$v.formData.modality"
            />
          </FormSection>

          <FormSection :title="$t('event.manage:field.description.restricted.access')">
            <Radio
              v-model="formData.restrictedAccess"
              :under-description="$t('event.manage:field.under.description.restricted.access')"
              :items="restrictedAccessOptions"
              horizontal
              :disabled="isEditing"
              :validation="$v.formData.restrictedAccess"
            />

            <AppMultiselect
              v-if="formData.restrictedAccess"
              v-model="formData.eventGroups"
              :options="groupList"
              :placeholder="$t('global:form.groups.select')"
              :validation="$v.formData.eventGroups"
              @onSelect="addEventGroup"
              @onRemove="removeEventGroup"
            />
          </FormSection>

          <FormSection :title="$t('global:form.events.hasExternalLink')">
            <Radio
              v-model="formData.hasExternalLink"
              :under-description="$t('event.manage:field.description.externalLink')"
              :items="yesNoOptions"
              horizontal
            />
          </FormSection>

          <InputField
            v-show="formData.hasExternalLink"
            v-model="formData.externalLink"
            :label="$t('global:form.events.slug')"
            :under-description="$t('global:form.events.external.example')"
            :validation="$v.formData.externalLink"
            :counter="100"
          />

          <FormSection :title="$t('global:form.themes')">
            <SelectField
              v-model="formData.eventThemes"
              :label="$t('global:form.theme')"
              multiple
              :items="themeList"
            />
            <!--
              <action :text="$t('global:form.solutions.add.theme')" type="button" flat></action>
            -->
          </FormSection>

          <FormSection :title="$t('global:form.events.audiences')">
            <SelectField
              v-model="formData.targetAudiences"
              :label="$t('global:form.events.audience')"
              multiple
              :items="targetAudienceList"
            />
            <!--
              <action :text="$t('global:form.solutions.add.profile')" type="button" flat></action>
            -->
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/events.css"></style>

<style lang="scss" src="../assets/scss/Events.scss"></style>

<style>
.link-selection,
.link-selection .form-section {
  display: flex;
  margin: 40px auto 32px;
  flex-direction: column;
}

.link-selection .form-section + .form-section {
  margin-top: 0;
}

.link-selection .form-section {
  width: 480px;
}

.link-selection .form-section.form-section--centered {
  text-align: center;
}

.link-selection .search-field,
.link-selection .link-table > div {
  width: 50%;
}

.link-table {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.events-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}
.warning-service-integration {
  padding: 10px;
  background-color: #ffc14d;
  margin-bottom: 10px;
}
.warning-rae-configuration {
  color: #fa6666;
  font-size: 12px;
}

.pragmatic-list {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.pragmatic-tools {
  padding-right: 15px;
}

.pragmatic-tools button {
  padding: 4px;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.form-input-file-label-text,
.form-section-title {
  text-transform: none;
}

.btn.view-file {
  font-size: 16px;
  text-transform: none;
}

.event-price-error-message {
  color: #ffc14d;
  font-size: 14px;
}
</style>
